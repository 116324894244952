

body{
	cursor: none;
}

a, button{
	cursor: none;
	&:hover{
		cursor: none;
	}
}

html:not(.touchevents){
	#cursor {
		width: 40px;
		height: 40px;
		border: 2px solid black;
		border-radius: 50%;
		position: absolute;
		display: block;
		background: transparent;
		transition:background 0.2s ease-out;
		pointer-events: none;
		z-index: 99999;

		&.interact{
			background: #000;
		}

		&.cross{
			border:0;
			&::after,
			&::before{
				content: '';
				width: 100%;
				height: 2px;
				display: block;
				position: absolute;
				background: #000;
				top: 50%;
				left: 0;
				opacity: 1;
				transition:all 0.2s ease-out;


			}
			&:before{
				transform: translateY(-50%) rotate(45deg);
			}
			&:after{
				transform:translateY(-50%) rotate(-45deg);
			}
			&.interact{
				&::after,
				&::before{
					opacity: 0;
				}
			}
		}
	}
}






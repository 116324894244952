
.fade-in{
	animation: fade-in 0.5s ease-out forwards;
}

.fade-out{
	animation: fade-out 0.5s ease-out both;
}


@keyframes fade-in {
	0% {
		display: none;
		visibility: hidden;
		opacity: 0;
		z-index: -1;
		
	}
	1%{
		display: block;
		visibility: visible;
		z-index: 2;
	}
	100% {

		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		display: block;
		visibility: visible;
		opacity: 1;
		z-index: 2;
	}
	95%{
		display: block;
		visibility: visible;
		opacity: 0;
		z-index: 2;
	}
	100% {
		display: none;
		visibility: hidden;
		z-index: -1;

	}
}



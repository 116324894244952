/**
 * app.css
 *
 * The entry point for the css.
 *
 */

/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */
 @import "tailwindcss/base";



/**
 * This injects any component classes registered by plugins.
 *
 */


 @import "tailwindcss/components";



/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */

 /**
 * Include vendor css.
 *
 */

 @import '../../node_modules/kursor/dist/kursor.css';

 /* Animation */


 /* Base */
 @import './base/_font-loading';
 @import './base/_typography';

 /* Helpers */
 @import './helpers/_globals';
 // @import './helpers/_grid';


 /* Components */

 @import './components/_custom-cursor';


#section-2{
	z-index: -1;
	opacity: 0;

	&.fade-in{

		opacity: 1;
		z-index: 1;
	}
}

 /* Layout */




 @import './helpers/_animation';
/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 */


 @import "tailwindcss/utilities";

 
/**
 * Include styles for individual pages
 *
 */


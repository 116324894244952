* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

*::selection {
  background: black;
  color: #fff;
}

body,
html{
  overflow-x:hidden;
}

.center-y{
  top:50%;
  transform:translateY( -50%);
}

.center-x{

  left:50%;
  transform:translateX(-50%);
}


.center{
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}

.markdown {
  a {
    @apply border-b;
    transition: opacity 0.2s ease;
  }

  &.no-underline {
    a {
      @apply border-none;
    }
  }
}



button{

  &:hover,
  &:focus{
    outline: none;
    box-shadow: none;
    border: 0;
  }
}


.underline-hover{
  position: relative;
  &::after{
    content: '';
    height: 2px;
    width: 0;
    background: #000;
    position: absolute;
    left: 0;
    bottom: 0;
    transition:width 0.2s ease-out;
  }
  &:hover{
    &::after{
      width: 100%;
    }
  }
}
@screen lg{

  .underline-hover--rotated{
    @extend .underline-hover;
    padding-left: 10px;
    &::after{
      left: 0;
      top: 0;
      width: 1px;
      height: 0;
      transition:height 0.2s ease-out;
    }
    &:hover{
      &::after{
        width: 2px;
        height: 100%;
      }
    }
  }
}



*{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

}

.heading {
	@apply font-sophillia;
}

.h1{
	@apply font-sophillia text-xl leading-small;
}
.h2{
	@apply font-sophillia text-lg leading-125-percent;
}
.h3{
	@apply font-sophillia text-md leading-125-percent;
}
.h4{
	@apply font-sophillia text-rg leading-125-percent;
}



.body-copy,
.body-text {
	@apply font-plain text-sm text-black;
}

